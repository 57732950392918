<template>
  <Modal v-model="editExportTemplateModal" :title="$t('key1000866')" width="900" :transfer="false" class-name="editExportTemplateStyle"
    :mask-closable="false" @on-visible-change="editExportTemplateChange">
    <div class="flex align-items-center">
      <span class="font-size-14 font-weight-bold color-333">{{ $t('key1000867') }}</span>
      <Input v-model.trim="templateName" :placeholder="$t('key1000565')" style="width: 250px; margin: 20px 0;"/>
    </div>
    <div class="flex align-items-center mb10 mt15" v-if="dimensionTypeList.length>0">
      <span class="title">{{ $t('key1000572') }}</span>
      <RadioGroup v-model="dimensionType" @on-change="handleDimensionData">
        <template v-for="(item,index) in dimensionTypeList">
          <Radio :key="index" :label="item.value" class="mr30" v-if="item.show">
            <span>{{ item.label }}</span>
            <span v-if="item.desc" class="font-size-13" style="color: #8D8D8D">
            <span>{{ item.desc }}</span>
            <Tooltip max-width="300" placement="top" class="ml5 cursor" transfer v-if="item.iconCenter" :content="item.iconCenter">
                <Icon size="18" type="md-help-circle"/>
            </Tooltip>
          </span>
          </Radio>
        </template>
      </RadioGroup>
    </div>
    <h2 class="title mb10">{{ $t('key1000573') }}</h2>
    <Checkbox class="mt5 mb10" style="display: inline-block;" v-model="editExportTemplateAll"
      :indeterminate="indeterminate" @on-change="changeFieldsAll">
      {{ $t('key1000574') }}
    </Checkbox>
    <div class="editExportTemplate_box">
      <template v-for="(item, index) in fieldsList">
        <div :class="handleHideTitle(item) ?'editExportTemplate_box_item' : ''" :key="index">
          <h3 class="fields_title" v-if="handleHideTitle(item)">{{ item.title }}</h3>
          <CheckboxGroup v-model="item.selectList" @on-change="(val)=>checkBoxChange(val, item)">
            <Row type="flex">
              <draggable v-model="item.checkBoxList" class="flex flex-wrap wid100P" @end="(data)=>draggableEnd(data,index)">
                <!--可拖动排序的字段-->
                <template v-for="(ele, idx) in item.checkBoxList.filter((k)=>!k.disabledDrag)">
                  <Col :xxl="fourItemCol" :xl="fourItemCol" :lg="fourItemCol" :md="fourItemCol">
                    <div class="flex align-items-center mb8 moveIcon">
                      <Icon type="ios-apps" class="font-size-17 mr5" style="cursor: move;"/>
                      <Checkbox :disabled="ele.disabled" :label="ele.paramKey" :key="idx" style="display: inline-block;">
                        {{ ele.paramValue }}
                      </Checkbox>
                    </div>
                  </Col>
                </template>
              </draggable>
              <!--不可以拖动排序的字段-->
              <template v-for="(ele, idx) in item.checkBoxList.filter((k)=>k.disabledDrag)">
                <Col :xxl="fourItemCol" :xl="fourItemCol" :lg="fourItemCol" :md="fourItemCol">
                  <div class="flex align-items-center mb8 moveIcon">
                    <Checkbox :disabled="ele.disabled" :label="ele.paramKey" :key="idx" style="display: inline-block;">
                      {{ ele.paramValue }}
                    </Checkbox>
                  </div>
                </Col>
              </template>
            </Row>
          </CheckboxGroup>
        </div>
      </template>
    </div>
    <template #footer>
      <Button class="mr15" @click="editExportTemplateModal = false">{{ $t('key1000097') }}</Button>
      <Button class="mr15" type="primary" @click="saveExportTemplateBtn" :disabled="exportTalg">{{ $t('key1000367') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import draggable from 'vuedraggable';

export default {
  name: 'editExportTemplateModal',
  mixins: [Mixin],
  props: {
    dimensionTypeList: {  // 导出数据维度类型的基础数据
      type: Array,
      default: () => {
        return []
      }
    },
    customFieldExportList: { // 自定义导出字段的基础数据
      type: Array,
      default: () => []
    },
    dimensionTalg: { // 按照不同的的维度展示不同的数据，默认是不同的维度展示相同的数据
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fieldsList: [],
      editExportTemplateModal: false,
      exportTalg: true,
      editExportTemplateAll: false,
      indeterminate: false,
      dimensionType: 1,
      cacheTypeObj: {}, // 缓存当前维度下已经选择的数据
      defaultCheckObj: null,
      recordSortList: {},
      templateName: '',
      ymsExportTemplateId: null,
    }
  },
  computed: {
    // 区分主题颜色
    themeColor() {
      // 分销商系统
      if (this.system === 'distributor') {
        return '#FD5425'
      }
      // 运营、供应商系统
      else {
        return '#2D8CF0'
      }
    },
  },
  methods: {
    // 获取模板详情数据
    getExportTemplateDetail(ymsExportTemplateId) {
      let v = this;
      v.defaultCheckObj = null;
      v.fieldsList = [];
      v.axios.get(api.get_ymsExportTemplate_detail + ymsExportTemplateId, {loading: true}).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            v.templateName = data.name;
            v.ymsExportTemplateId = ymsExportTemplateId;
            v.defaultCheckObj = JSON.parse(data.paramValue) || null;
            // 默认选中有权限的维度
            if (v.dimensionTypeList && v.dimensionTypeList.length > 0) {
              let type = 1;
              let list = v.dimensionTypeList.filter((item) => {
                return item.show
              });
              if (list.length > 0) {
                type = list[0].value;
                list.map((ele) => {
                  if (v.defaultCheckObj.dimensionType === ele.value) {
                    type = ele.value;
                  }
                });
              }
              v.dimensionType = v.defaultCheckObj.dimensionType || type;
            }
          }
          if (v.defaultCheckObj) {
            for (let key in v.defaultCheckObj) {
              if (key !== 'dimensionType') {
                let list = v.defaultCheckObj[key];
                if (typeof list[0] === 'object' && list[0] !== null) {
                  v.recordSortList[key] = list.map((ele) => {
                    return ele.columnName;
                  })
                } else {
                  v.recordSortList[key] = list;
                }
              }
            }
          }
          v.handleDimensionData(v.defaultCheckObj);
          v.editExportTemplateModal = true;
          v.$forceUpdate()
        }
      });
    },
    // 按照不同的维度处理数据
    handleDimensionData(obj) {
      let v = this;
      let newData = JSON.parse(JSON.stringify(v.customFieldExportList));
      // 根据指定的key进行排序
      if (Object.keys(v.recordSortList).length > 0) {
        newData.map((item) => {
          const titleKey = item.titleKey;
          const sortList = v.recordSortList[titleKey] || [];
          if (sortList.length > 0) {
            item.checkBoxList.sort((a, b) => {
              const indexA = sortList.indexOf(a.paramKey);
              const indexB = sortList.indexOf(b.paramKey);
              return indexA - indexB;
            });
          }
        });
      }
      v.editExportTemplateAll = false;
      v.indeterminate = false;
      // 是否需要按照不同的维度展示数据
      setTimeout(() => {
        if (v.dimensionTypeList.length > 0) {
          if (v.dimensionTalg) {
            let newList = newData.map(item => {
              item.checkBoxList = item.checkBoxList.filter(ele => ele.type.includes(v.dimensionType));
              return item;
            }).filter(talg => talg.checkBoxList.length > 0);
            v.handleCheckBoxData(obj, newList);
          } else {
            v.handleCheckBoxData(obj, newData);
          }
        } else {
          v.handleCheckBoxData(obj, newData);
        }
      }, 50);
    },
    // 处理选中的数据回显
    handleCheckBoxData(obj, newData) {
      let keyList = [];
      if (Object.keys(this.cacheTypeObj).length > 0 && this.cacheTypeObj[this.dimensionType]
        && this.cacheTypeObj[this.dimensionType].length > 0) {
        keyList = this.cacheTypeObj[this.dimensionType]
      }
      this.fieldsList = [];
      newData.map((item) => {
        let list = [];
        if (obj && Object.keys(obj).length > 0) {
          // 按照维度回显对应的数据
          if (this.dimensionTypeList.length > 0) {
            let arr = [];
            let dimensionType = obj['dimensionType'] || null;
            if (dimensionType === this.dimensionType) {
              let newList = obj[item.titleKey] || [];
              if (newList.length > 0) {
                if (typeof newList[0] === 'object' && newList[0] !== null) {
                  newList.map((ele) => {
                    if (ele.isChoose === '1') {
                      arr.push(ele.columnName);
                    }
                  });
                } else {
                  arr = obj[item.titleKey] || [];
                }
              }
            }
            if (item.selectList.length > 0) {
              let list = [...item.selectList, ...arr];
              item.selectList = this.uniqueFunc(list);
            } else {
              item.selectList = arr || [];
            }
          } else {
            let arr = [];
            let newList = obj[item.titleKey] || [];
            if (newList.length > 0) {
              if (typeof newList[0] === 'object' && newList[0] !== null) {
                newList.map((ele) => {
                  if (ele.isChoose === '1') {
                    arr.push(ele.columnName);
                  }
                });
              } else {
                arr = obj[item.titleKey] || [];
              }
            }
            if (item.selectList.length > 0) {
              let list = [...item.selectList, ...arr];
              item.selectList = this.uniqueFunc(list);
            } else {
              item.selectList = arr || [];
            }
          }
        } else {
          let newList = [];
          let selectKeyList = [];
          let dimensionType = null;
          if (this.defaultCheckObj && Object.keys(this.defaultCheckObj).length > 0) {
            dimensionType = this.defaultCheckObj['dimensionType'] || null;
            selectKeyList = this.defaultCheckObj[item.titleKey] || [];
          }
          if (this.dimensionTypeList.length > 0) {
            if (dimensionType === this.dimensionType) {
              newList = handleData(selectKeyList)
            }
          } else {
            newList = handleData(selectKeyList)
          }
          let arr = [...newList, ...keyList];
          this.cacheTypeObj[this.dimensionType] = arr;
          item.checkBoxList.map((ele) => {
            if (arr.includes(ele.paramKey)) {
              list.push(ele.paramKey);
            }
          });
          if (item.selectList.length > 0) {
            let newList = [...item.selectList, ...list];
            item.selectList = this.uniqueFunc(newList);
          } else {
            item.selectList = list;
          }
        }
      });

      function handleData(selectKeyList) {
        let newList = [];
        if (selectKeyList.length > 0) {
          if (typeof selectKeyList[0] === 'object' && selectKeyList[0] !== null) {
            selectKeyList.map((ele) => {
              if (ele.isChoose === '1') {
                newList.push(ele.columnName);
              }
            });
          } else {
            newList = selectKeyList;
          }
        }
        return newList;
      }

      this.fieldsList = newData;
      this.$forceUpdate();
    },
    // 保存导出模板的字段
    saveExportTemplateBtn() {
      let v = this;
      let paramValueObj = {};
      v.fieldsList.map((item) => {
        paramValueObj[item.titleKey] = [];
        item.checkBoxList.map((talg) => {
          paramValueObj[item.titleKey].push({
            columnName: talg.paramKey,
            isChoose: '0',
          })
        });
      });
      if (Object.keys(paramValueObj).length > 0) {
        v.fieldsList.map((item) => {
          if (item.selectList.length > 0) {
            let list = paramValueObj[item.titleKey];
            item.selectList.map((key) => {
              list.map((talg) => {
                if (talg.columnName === key) {
                  talg.isChoose = '1';
                }
              });
            });
          }
        });
      }
      // 如果是按照维度导出的，需要存维度类型，用于回显显示那个维度下的数据
      if (v.dimensionTypeList.length > 0) {
        paramValueObj['dimensionType'] = v.dimensionType;
      }
      paramValueObj['ymsExportTemplateId'] = v.ymsExportTemplateId;
      // 根据指定的key进行排序
      if (Object.keys(v.recordSortList).length > 0) {
        for (let key in paramValueObj) {
          if (!['dimensionType', 'ymsExportTemplateId'].includes(key)) {
            let sortList = v.recordSortList[key] || [];
            let list = paramValueObj[key] || [];
            if (sortList.length > 0) {
              list.sort((a, b) => {
                const indexA = sortList.indexOf(a.columnName);
                const indexB = sortList.indexOf(b.columnName);
                return indexA - indexB;
              });
              paramValueObj[key] = list;
            }
          }
        }
      }
      let query = {
        name: v.templateName,
        paramValue: JSON.stringify(paramValueObj),
        ymsExportTemplateId: v.ymsExportTemplateId
      };
      if (v.templateName) {
        v.axios.put(api.put_ymsExportTemplate, query, {loading: true, loadingText: alias2f085dd487c1465383484787b29fef2c.t('key1000212')}).then(res => {
          if (res.data.code === 0) {
            v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000868'));
            v.editExportTemplateModal = false;
            let obj = {
              name: v.templateName,
              ymsExportTemplateId: v.ymsExportTemplateId
            }
            v.$emit('updateExportTemplate', obj);
          }
        });
      } else {
        v.$Message.warning(alias2f085dd487c1465383484787b29fef2c.t('key1000579'));
        return false;
      }
    },
    // 监听弹窗
    editExportTemplateChange(value) {
      if (value) {
        this.stopScroll();
      } else {
        this.fieldsList = [];
        this.cacheTypeObj = {};
        this.defaultCheckObj = null;
        this.exportTalg = true;
        this.recordSortList = {};
      }
    },
    // 全选
    changeFieldsAll(value) {
      let v = this;
      v.defaultCheckObj = null;
      let selectList = [];
      if (value) {
        v.fieldsList.map((item) => {
          item.selectList = item.checkBoxList.map((ele) => {
            selectList.push(ele.paramKey)
            return ele.paramKey
          });
        })
      } else {
        v.fieldsList.map((item) => {
          item.selectList = [];
          item.checkBoxList.map((ele) => {
            if (ele.disabled) {
              item.selectList.push(ele.paramKey);
              selectList.push(ele.paramKey);
            }
          });
        });
        v.cacheTypeObj[v.dimensionType] = selectList;
      }
    },
    // 处理隐藏标题
    handleHideTitle(data) {
      let val = true;
      let talg = data.checkBoxList.some((item) => {
        return item.type && item.type.length > 0
      });
      if (talg) {
        val = data.checkBoxList[0].type.includes(this.dimensionType);
      } else {
        val = true;
      }
      return val;
    },
    // 监听数据变化实时切换选中状态
    handleCheckBoxStatus(data) {
      let selectList = [];
      let checkBoxList = [];
      data.map((item) => {
        item.selectList.map((ele) => {
          selectList.push(ele);
        });
        item.checkBoxList.map((talg) => {
          checkBoxList.push(talg);
        })
      });
      if (selectList.length > 0) {
        this.indeterminate = true;
        if (selectList.length >= checkBoxList.length) {
          this.editExportTemplateAll = true;
          this.indeterminate = false;
        }
        this.cacheTypeObj[this.dimensionType] = selectList;
      } else {
        this.editExportTemplateAll = false;
        this.indeterminate = false;
      }
      this.exportTalg = selectList.length <= 0;
      this.$forceUpdate();
    },
    checkBoxChange(val, item) {
      if (this.defaultCheckObj) {
        let list = this.defaultCheckObj[item.titleKey] || [];
        if (list.length > 0) {
          this.defaultCheckObj = null;
        }
      }
    },
    // 拖动排序结束之后
    draggableEnd({oldIndex, newIndex}, currentIndex) {
      let v = this;
      let selectList = v.fieldsList[currentIndex].selectList;
      let originalSelectList = JSON.parse(JSON.stringify(selectList));
      v.fieldsList[currentIndex].selectList = [];
      v.fieldsList.map((item) => {
        v.recordSortList[item.titleKey] = item.checkBoxList.map((ele) => {
          return ele.paramKey
        })
      });
      setTimeout(() => {
        v.$set(v.fieldsList[currentIndex], 'selectList', originalSelectList);
        v.$forceUpdate();
      }, 100)
    }
  },
  watch: {
    fieldsList: {
      handler(data) {
        if (data && data.length > 0) {
          this.handleCheckBoxStatus(data);
        }
      },
      immediate: true,
      deep: true
    }
  },
  components: {
    draggable
  }
};
</script>

<style lang="less" scoped>
/deep/ .editExportTemplateStyle {

  .ivu-modal-body {
    padding-top: 8px;
  }

  .title {
    color: #333;
    font-size: 14px;
    font-weight: bold;
  }

  .editExportTemplate_box {
    .editExportTemplate_box_item {
      margin-bottom: 25px;

      .fields_title {
        font-size: 14px;
        color: #333;
        margin-bottom: 8px;
        font-weight: bold;
      }

      /*.ivu-checkbox-group-item {
        margin-bottom: 8px;
      }*/
    }
  }

  .ivu-alert {
    padding: 8px 14px 8px 38px;

    .ivu-alert-icon .ivu-icon {
      font-size: 20px;
    }
  }
}
</style>
